import Vue from "vue";
import Router from "vue-router";

const routes = [
  {
    path: "/",
    name: "HOME_MAIN",
    component: () => import("@/view/main"),
  },
  {
    path: "/about",
    name: "HOME_ABOUT",
    component: () => import("@/view/about"),

  },
  {
    path: "/introduce",
    name: "HOME_INTRODUCE",
    component: () => import("@/view/introduce"),

  },
  {
    path: "/notice",
    name: "HOME_NEW",
    component: () => import("@/view/notice"),

  },
  {
    path: "/scene",
    name: "HOME_SCENCE",
    component: () => import("@/view/scene"),
  },
  {
    path: "/software",
    name: "HOME_SOFTWARE",
    component: () => import("@/view/software"),
  },
];
Vue.use(Router);

const router = new Router({
  routes,
  mode: "history",
});

export default router;
